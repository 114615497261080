// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../NewTrade/newTrade.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reEnter_re_enter__jLDzC{
    width: 400px;
    margin: auto;
}

/* .re_enter h1{
    position: relative;
    top :-90px;
    white-space: nowrap;
    margin-bottom: -40px;
} */

.reEnter_form_reEnter__TgQs6 {
}
 
.reEnter_input_wrap__8DsLw, .reEnter_input_wrapLong__ohEfW {
  display: block;
  width: 45%;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/Pages/ReEnter/reEnter.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;;;;;GAKG;;AAEH;AAEA;;AAEA;EACE,cAAc;EACd,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".re_enter{\n    width: 400px;\n    margin: auto;\n}\n\n/* .re_enter h1{\n    position: relative;\n    top :-90px;\n    white-space: nowrap;\n    margin-bottom: -40px;\n} */\n\n.form_reEnter {\n    composes: form_enter from \"../NewTrade/newTrade.module.css\"\n}\n \n.input_wrap, .input_wrapLong {\n  display: block;\n  width: 45%;\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"re_enter": `reEnter_re_enter__jLDzC`,
	"form_reEnter": `reEnter_form_reEnter__TgQs6 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["form_enter"]}`,
	"input_wrap": `reEnter_input_wrap__8DsLw`,
	"input_wrapLong": `reEnter_input_wrapLong__ohEfW`
};
export default ___CSS_LOADER_EXPORT___;
