// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AllTrades_btn_wrapper__Sweu\\+{
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;
   
}

.AllTrades_btn__65lIf {
    display: block;
    width: 100px ;
    margin:  10px  10px !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/AllTrades/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,uBAAuB;;AAE3B;;AAEA;IACI,cAAc;IACd,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".btn_wrapper{\n    text-align: center;\n    margin: auto;\n    display: flex;\n    justify-content: center;\n   \n}\n\n.btn {\n    display: block;\n    width: 100px ;\n    margin:  10px  10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn_wrapper": `AllTrades_btn_wrapper__Sweu+`,
	"btn": `AllTrades_btn__65lIf`
};
export default ___CSS_LOADER_EXPORT___;
