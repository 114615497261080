// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create_form_create__bCtPc {
    display: flex;
    flex-direction: raw;
    width: 100%;
    max-width: 400px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 30px;
    margin-bottom: 20px;
    box-shadow: 1px 2px 10px 1px rgb(201, 201, 201);
    
}

.create_form_create__bCtPc  label { 
    margin-top: 6px;
    margin-right: 10px;
    display: block;
    width: 160px;
    text-align: right;
}

.create_form_create__bCtPc input{
    display: block;
    width: 200px;
    border-radius: 10px;
    padding: 4px;
    margin: 2px;
    text-align: right;
    border: 1px solid black;
}

.create_form_create__bCtPc .create_btns__BPXr\\+{
    width: 100%;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.create_form_create__bCtPc .create_btns__BPXr\\+ input[name="abandon"], .create_btns__BPXr\\+ input[name="validation"]{
    width: 45%;
    text-align: center;
}
 
 

 
`, "",{"version":3,"sources":["webpack://./src/Pages/Strategies/Components/create.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,+CAA+C;;AAEnD;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,kBAAkB;AACtB","sourcesContent":[".form_create {\n    display: flex;\n    flex-direction: raw;\n    width: 100%;\n    max-width: 400px;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px;\n    border-radius: 30px;\n    margin-bottom: 20px;\n    box-shadow: 1px 2px 10px 1px rgb(201, 201, 201);\n    \n}\n\n.form_create  label { \n    margin-top: 6px;\n    margin-right: 10px;\n    display: block;\n    width: 160px;\n    text-align: right;\n}\n\n.form_create input{\n    display: block;\n    width: 200px;\n    border-radius: 10px;\n    padding: 4px;\n    margin: 2px;\n    text-align: right;\n    border: 1px solid black;\n}\n\n.form_create .btns{\n    width: 100%;\n    display: flex;\n    padding: 10px;\n    align-items: center;\n    justify-content: center;\n}\n\n.form_create .btns input[name=\"abandon\"], .btns input[name=\"validation\"]{\n    width: 45%;\n    text-align: center;\n}\n \n \n\n \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_create": `create_form_create__bCtPc`,
	"btns": `create_btns__BPXr+`
};
export default ___CSS_LOADER_EXPORT___;
