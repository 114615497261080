// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolioAfter_portTable__20Bsl {
    table-layout: fixed;
    width: 100%;
    margin: 10px auto;
    border-collapse: collapse;
    border: 3px solid var(--color-bgTableaux);
    border-radius: 5px;
}
.portfolioAfter_portTable__20Bsl td {
  padding: 2px 3px;
  text-align: right;
}

.portfolioAfter_portTable__20Bsl td:first-of-type {
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  width:136px;
}

.portfolioAfter_portTable__20Bsl td:nth-of-type(2) {
  width:20%;
  text-align: right;
   
}
.portfolioAfter_portTable__20Bsl td:nth-of-type(4){
  width:15%;
  text-align: right;
   
}
.portfolioAfter_portTable__20Bsl td:nth-of-type(3),  td:nth-of-type(5){
  text-align: left;
}
 
 
.portfolioAfter_portTable__20Bsl tr:nth-last-of-type(even){
    background-color: var(--color-bgTableaux);
}

@media screen and ( min-width: 390px ) {
  .portfolioAfter_portTable__20Bsl{
    max-width: 400px;
  }
  .portfolioAfter_portTable__20Bsl td:first-of-type {
  width:40%;
  padding-right: 20px;
}
}



.portfolioAfter_minus__yWg\\+g{
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.75);
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewTrade/Components/portfolioAfter.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,yBAAyB;IACzB,yCAAyC;IACzC,kBAAkB;AACtB;AACA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,SAAS;EACT,iBAAiB;;AAEnB;AACA;EACE,SAAS;EACT,iBAAiB;;AAEnB;AACA;EACE,gBAAgB;AAClB;;;AAGA;IACI,yCAAyC;AAC7C;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;EACA,SAAS;EACT,mBAAmB;AACrB;AACA;;;;AAIA;IACI,iBAAiB;IACjB,0BAA0B;AAC9B","sourcesContent":[".portTable {\n    table-layout: fixed;\n    width: 100%;\n    margin: 10px auto;\n    border-collapse: collapse;\n    border: 3px solid var(--color-bgTableaux);\n    border-radius: 5px;\n}\n.portTable td {\n  padding: 2px 3px;\n  text-align: right;\n}\n\n.portTable td:first-of-type {\n  text-align: right;\n  white-space: nowrap;\n  overflow: hidden;\n  width:136px;\n}\n\n.portTable td:nth-of-type(2) {\n  width:20%;\n  text-align: right;\n   \n}\n.portTable td:nth-of-type(4){\n  width:15%;\n  text-align: right;\n   \n}\n.portTable td:nth-of-type(3),  td:nth-of-type(5){\n  text-align: left;\n}\n \n \n.portTable tr:nth-last-of-type(even){\n    background-color: var(--color-bgTableaux);\n}\n\n@media screen and ( min-width: 390px ) {\n  .portTable{\n    max-width: 400px;\n  }\n  .portTable td:first-of-type {\n  width:40%;\n  padding-right: 20px;\n}\n}\n\n\n\n.minus{\n    font-size: 1.2rem;\n    color: rgba(0, 0, 0, 0.75);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portTable": `portfolioAfter_portTable__20Bsl`,
	"minus": `portfolioAfter_minus__yWg+g`
};
export default ___CSS_LOADER_EXPORT___;
