// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.errorServer_errorServer__V1f3o{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
}
.errorServer_errorServer__V1f3o H1 {
      top :20px;
      margin: 50px 5px 10px 5px;   
      left: auto;
      transform: translate(0);
      white-space:  wrap;
}

.errorServer_errorServer__V1f3o img {
    width: 80%;
    max-width: 500px;

}`, "",{"version":3,"sources":["webpack://./src/Pages/ErrorServer/errorServer.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;;AAE3B;AACA;MACM,SAAS;MACT,yBAAyB;MACzB,UAAU;MACV,uBAAuB;MACvB,kBAAkB;AACxB;;AAEA;IACI,UAAU;IACV,gBAAgB;;AAEpB","sourcesContent":["\n.errorServer{\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n   \n}\n.errorServer H1 {\n      top :20px;\n      margin: 50px 5px 10px 5px;   \n      left: auto;\n      transform: translate(0);\n      white-space:  wrap;\n}\n\n.errorServer img {\n    width: 80%;\n    max-width: 500px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorServer": `errorServer_errorServer__V1f3o`
};
export default ___CSS_LOADER_EXPORT___;
