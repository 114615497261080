// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.strategies_manageStrategies__NwFZ2 table{
    table-layout: fixed;
    
    width: 200px;
    margin: 30px auto 0 auto;
    border-collapse: collapse;
    border: 2px solid var(--color-bgTableaux);
    position: relative;
     
}

.strategies_manageStrategies__NwFZ2 th, .strategies_manageStrategies__NwFZ2 td  {
  width:100px;
  text-align: center;
  border: 2px solid var(--color-bgTableaux);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.strategies_manageStrategies__NwFZ2 td {
  padding: 3px;
  text-align: right;
}

.strategies_manageStrategies__NwFZ2 th:first-of-type,
.strategies_manageStrategies__NwFZ2 td:first-of-type  {
  width:10px;
  padding-right:10px;
  text-align: center;
}

.strategies_manageStrategies__NwFZ2 th:nth-of-type(2),
.strategies_manageStrategies__NwFZ2 td:nth-of-type(2){
  width:40px;
}

.strategies_manageStrategies__NwFZ2 tr:nth-last-of-type(even){
    background-color: var(--color-bgTableaux);
}

.strategies_wrapper__p2Tj2{
margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.strategies_btns__RS6k-{
  margin: auto;
}

.strategies_btns__RS6k- input[type="button"],
.strategies_btns__RS6k- input[type="submit"]{
  margin: 10px;
  padding: 10px;
}
.strategies_btns__RS6k- input[type="button"]:hover,
.strategies_btns__RS6k- input[type="submit"]:hover{
  padding: 9px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Strategies/strategies.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;;IAEnB,YAAY;IACZ,wBAAwB;IACxB,yBAAyB;IACzB,yCAAyC;IACzC,kBAAkB;;AAEtB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,yCAAyC;EACzC,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;;AAGA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;;EAEE,UAAU;EACV,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;;EAEE,UAAU;AACZ;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;AACA,gBAAgB;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,YAAY;EACZ,aAAa;AACf;AACA;;EAEE,YAAY;AACd","sourcesContent":[".manageStrategies table{\n    table-layout: fixed;\n    \n    width: 200px;\n    margin: 30px auto 0 auto;\n    border-collapse: collapse;\n    border: 2px solid var(--color-bgTableaux);\n    position: relative;\n     \n}\n\n.manageStrategies th, .manageStrategies td  {\n  width:100px;\n  text-align: center;\n  border: 2px solid var(--color-bgTableaux);\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n\n.manageStrategies td {\n  padding: 3px;\n  text-align: right;\n}\n\n.manageStrategies th:first-of-type,\n.manageStrategies td:first-of-type  {\n  width:10px;\n  padding-right:10px;\n  text-align: center;\n}\n\n.manageStrategies th:nth-of-type(2),\n.manageStrategies td:nth-of-type(2){\n  width:40px;\n}\n\n.manageStrategies tr:nth-last-of-type(even){\n    background-color: var(--color-bgTableaux);\n}\n\n.wrapper{\nmargin-top: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n\n.btns{\n  margin: auto;\n}\n\n.btns input[type=\"button\"],\n.btns input[type=\"submit\"]{\n  margin: 10px;\n  padding: 10px;\n}\n.btns input[type=\"button\"]:hover,\n.btns input[type=\"submit\"]:hover{\n  padding: 9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manageStrategies": `strategies_manageStrategies__NwFZ2`,
	"wrapper": `strategies_wrapper__p2Tj2`,
	"btns": `strategies_btns__RS6k-`
};
export default ___CSS_LOADER_EXPORT___;
