// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.globalPortfolio_wrapper__Zj3Na{
    width: 100%;
    margin: auto;
    border-radius: 30px;
    margin-bottom: 20px;
    box-shadow: 1px 2px 10px 1px rgb(201, 201, 201);
    padding: 10px;
}

@media  screen and (min-width:430px) {
   .globalPortfolio_wrapper__Zj3Na{
    width: 430px;
    }
}

/* Attention pour les dimensions du meter indispensable de passer
par des variables ( et de les setter 2* -> pour la div container et 
Pour le svg) */

.globalPortfolio_global_portfolio__XS6RB{
    --metW :80vw;
    --metH : calc((var(--metW))*0.58);
    text-align: center;
   /* width: 430px;*/
    margin: auto;
}

.globalPortfolio_global_portfolio__XS6RB h2{
    height: 50px;
}

@media  screen and (min-width:480px) {
   .globalPortfolio_global_portfolio__XS6RB{
    --metW :400px;
    --metH :230px; 
    }
}

/* .global_portfolio h1{
    position: relative;
    top :-90px;
    left: calc(50vw);
    transform: translateX(-50%);
    white-space: nowrap;
    margin-bottom:-40px;
} */

.globalPortfolio_global_portfolio__XS6RB .globalPortfolio_meter_container__cVXTw {
    text-align: center;
    width: var(--metW);
    height: var(--metH); 
    margin: auto;
}

`, "",{"version":3,"sources":["webpack://./src/Pages/GlobalPortfolio/globalPortfolio.module.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,+CAA+C;IAC/C,aAAa;AACjB;;AAEA;GACG;IACC,YAAY;IACZ;AACJ;;AAEA;;cAEc;;AAEd;IACI,YAAY;IACZ,iCAAiC;IACjC,kBAAkB;GACnB,iBAAiB;IAChB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;GACG;IACC,aAAa;IACb,aAAa;IACb;AACJ;;AAEA;;;;;;;GAOG;;AAQH;IACI,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":["\n.wrapper{\n    width: 100%;\n    margin: auto;\n    border-radius: 30px;\n    margin-bottom: 20px;\n    box-shadow: 1px 2px 10px 1px rgb(201, 201, 201);\n    padding: 10px;\n}\n\n@media  screen and (min-width:430px) {\n   .wrapper{\n    width: 430px;\n    }\n}\n\n/* Attention pour les dimensions du meter indispensable de passer\npar des variables ( et de les setter 2* -> pour la div container et \nPour le svg) */\n\n.global_portfolio{\n    --metW :80vw;\n    --metH : calc((var(--metW))*0.58);\n    text-align: center;\n   /* width: 430px;*/\n    margin: auto;\n}\n\n.global_portfolio h2{\n    height: 50px;\n}\n\n@media  screen and (min-width:480px) {\n   .global_portfolio{\n    --metW :400px;\n    --metH :230px; \n    }\n}\n\n/* .global_portfolio h1{\n    position: relative;\n    top :-90px;\n    left: calc(50vw);\n    transform: translateX(-50%);\n    white-space: nowrap;\n    margin-bottom:-40px;\n} */\n\n\n:export {\n    meterWidth: var(--metW);\n    meterHeight: var(--metH);\n}\n\n.global_portfolio .meter_container {\n    text-align: center;\n    width: var(--metW);\n    height: var(--metH); \n    margin: auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meterWidth": `var(--metW)`,
	"meterHeight": `var(--metH)`,
	"wrapper": `globalPortfolio_wrapper__Zj3Na`,
	"global_portfolio": `globalPortfolio_global_portfolio__XS6RB`,
	"meter_container": `globalPortfolio_meter_container__cVXTw`
};
export default ___CSS_LOADER_EXPORT___;
