// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchStock_search__6fr5y form {
    margin: auto;
    text-align: center;
    font-size: larger;
}

.searchStock_search__6fr5y input {
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.searchStock_search__6fr5y table {
  table-layout: fixed;
  width: 100%;
  max-width: 480px;
  margin: auto;
  border-collapse: collapse;
  border: 3px solid var(--color-bgTableaux);
}

.searchStock_search__6fr5y tbody{
  width: 100vw;
}

.searchStock_search__6fr5y th:first-of-type,
.searchStock_search__6fr5y td:first-of-type {
   width: 132px;
    
} 

.searchStock_search__6fr5y th:nth-of-type(2),
.searchStock_search__6fr5y td:nth-of-type(2) {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
       
}


.searchStock_search__6fr5y th:nth-of-type(3),
.searchStock_search__6fr5y td:nth-of-type(3)  {
  width: 70px;
  text-align: center;
  overflow: hidden;
}
.searchStock_search__6fr5y th:nth-of-type(4),
.searchStock_search__6fr5y td:nth-of-type(4) {
  width: 50px;
  text-align: center;
}

.searchStock_search__6fr5y tr:nth-last-of-type(even) {
  background-color: var(--color-bgTableaux);
}

.searchStock_search__6fr5y td,
.searchStock_search__6fr5y th {
  padding: 5px 2px;
  border: 3px solid var(--color-bgTableaux);
}

.searchStock_search__6fr5y tbody tr:hover td {
  cursor: pointer;
  background-color: var(--color-2-dark);
  color: var(--txt-color-light);
}
`, "",{"version":3,"sources":["webpack://./src/Pages/NewTrade/Components/searchStock.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,yCAAyC;AAC3C;;AAEA;EACE,YAAY;AACd;;AAEA;;GAEG,YAAY;;AAEf;;AAEA;;EAEE,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;;AAEzB;;;AAGA;;EAEE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;AACA;;EAEE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;;EAEE,gBAAgB;EAChB,yCAAyC;AAC3C;;AAEA;EACE,eAAe;EACf,qCAAqC;EACrC,6BAA6B;AAC/B","sourcesContent":[".search form {\n    margin: auto;\n    text-align: center;\n    font-size: larger;\n}\n\n.search input {\n  padding: 5px;\n  border-radius: 10px;\n  margin-bottom: 10px;\n}\n\n.search table {\n  table-layout: fixed;\n  width: 100%;\n  max-width: 480px;\n  margin: auto;\n  border-collapse: collapse;\n  border: 3px solid var(--color-bgTableaux);\n}\n\n.search tbody{\n  width: 100vw;\n}\n\n.search th:first-of-type,\n.search td:first-of-type {\n   width: 132px;\n    \n} \n\n.search th:nth-of-type(2),\n.search td:nth-of-type(2) {\n  text-align: center;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n       \n}\n\n\n.search th:nth-of-type(3),\n.search td:nth-of-type(3)  {\n  width: 70px;\n  text-align: center;\n  overflow: hidden;\n}\n.search th:nth-of-type(4),\n.search td:nth-of-type(4) {\n  width: 50px;\n  text-align: center;\n}\n\n.search tr:nth-last-of-type(even) {\n  background-color: var(--color-bgTableaux);\n}\n\n.search td,\n.search th {\n  padding: 5px 2px;\n  border: 3px solid var(--color-bgTableaux);\n}\n\n.search tbody tr:hover td {\n  cursor: pointer;\n  background-color: var(--color-2-dark);\n  color: var(--txt-color-light);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `searchStock_search__6fr5y`
};
export default ___CSS_LOADER_EXPORT___;
