// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details_toinfo__DRIwW:hover  {
  cursor:help;
}
.details_toinfo__DRIwW:hover::after {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  content: attr(datainfo);
  background-color: rgb(237, 237, 237);
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%);
  padding: 15px;
  border-radius: 13px;
  line-height: 1.5;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/Pages/ManagePortfolio/Components/details.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,wBAAmB;EAAnB,mBAAmB;EACnB,uBAAuB;EACvB,oCAAoC;EACpC,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,uCAAuC;AACzC","sourcesContent":[".toinfo:hover  {\n  cursor:help;\n}\n.toinfo:hover::after {\n  width: fit-content;\n  height: fit-content;\n  content: attr(datainfo);\n  background-color: rgb(237, 237, 237);\n  position: absolute;\n  top: -30px;\n  left: 50%;\n  transform: translate(-50%);\n  padding: 15px;\n  border-radius: 13px;\n  line-height: 1.5;\n  z-index: 100;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toinfo": `details_toinfo__DRIwW`
};
export default ___CSS_LOADER_EXPORT___;
