// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../NewTrade/newTrade.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exitTrade_exit__YQgU\\+{
    width: 300px;
    margin: auto;
}
/* .exit h1{
    position: relative;
    top :-90px;
    white-space: nowrap;
    margin-bottom: -40px;
}
  */
.exitTrade_form_exit__Jq8DQ {
}
.exitTrade_input_wrap__tYwPn {
  display: block;
  width: 45%;
  text-align: left;
}

`, "",{"version":3,"sources":["webpack://./src/Pages/ExitTrade/exitTrade.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;AAChB;AACA;;;;;;GAMG;AACH;AAEA;AACA;EACE,cAAc;EACd,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".exit{\n    width: 300px;\n    margin: auto;\n}\n/* .exit h1{\n    position: relative;\n    top :-90px;\n    white-space: nowrap;\n    margin-bottom: -40px;\n}\n  */\n.form_exit {\n    composes: form_enter from \"../NewTrade/newTrade.module.css\"\n}\n.input_wrap {\n  display: block;\n  width: 45%;\n  text-align: left;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exit": `exitTrade_exit__YQgU+`,
	"form_exit": `exitTrade_form_exit__Jq8DQ ${___CSS_LOADER_ICSS_IMPORT_0___.locals["form_enter"]}`,
	"input_wrap": `exitTrade_input_wrap__tYwPn`
};
export default ___CSS_LOADER_EXPORT___;
