// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign_sign__nGGNe {
    padding: 20px;
    text-align: center;
}

.sign_sign__nGGNe h1 {
  
    top : -60px;
     

} 

.sign_sign__nGGNe form {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 10px auto;
    text-align: left;
     
}

.sign_sign__nGGNe input:not([submit]) {
    margin-bottom: 10px;
    height: 25px;
    padding: 2px;
    position: relative;
     
}
.sign_sign__nGGNe input[type="submit"] {
    height: 35px;
   color: black;
}

.sign_sign__nGGNe input{
    width: 300px;
}

.sign_parentOfPwd__XY816{
    position: relative;
     width: 300px;
}

.sign_eye__i9xeo {
    position: absolute;
    top:0;
    right: 0px;
    display: inline-block;
    cursor: pointer;
    width: 30px;
    height: 25px;
    margin-top: 2px;
     
}



.sign_sign__nGGNe p {
    text-align: center;
}



.sign_sign__nGGNe a {
    display: inline;
    color: black;
    background: transparent;
    font-weight: bolder;
    
}

.sign_sign__nGGNe img {
    width: 300px;
    height: 240px;
}

.sign_sign__nGGNe input[type="checkbox"] {
    width: 50px;
}
.sign_labelCheck__TpPQM{
    display: flex;
    align-items: center;
    justify-content: center;
}
.sign_space__uFFV7{
    display: inline-block;
    width: 20px;
    vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/Pages/SignIn/sign.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;;IAEI,WAAW;;;AAGf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;;AAEpB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;;AAEtB;AACA;IACI,YAAY;GACb,YAAY;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;KACjB,YAAY;AACjB;;AAEA;IACI,kBAAkB;IAClB,KAAK;IACL,UAAU;IACV,qBAAqB;IACrB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,eAAe;;AAEnB;;;;AAIA;IACI,kBAAkB;AACtB;;;;AAIA;IACI,eAAe;IACf,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;;AAEvB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,qBAAqB;IACrB,WAAW;IACX,sBAAsB;AAC1B","sourcesContent":[".sign {\n    padding: 20px;\n    text-align: center;\n}\n\n.sign h1 {\n  \n    top : -60px;\n     \n\n} \n\n.sign form {\n    display: flex;\n    flex-direction: column;\n    width: 300px;\n    margin: 10px auto;\n    text-align: left;\n     \n}\n\n.sign input:not([submit]) {\n    margin-bottom: 10px;\n    height: 25px;\n    padding: 2px;\n    position: relative;\n     \n}\n.sign input[type=\"submit\"] {\n    height: 35px;\n   color: black;\n}\n\n.sign input{\n    width: 300px;\n}\n\n.parentOfPwd{\n    position: relative;\n     width: 300px;\n}\n\n.eye {\n    position: absolute;\n    top:0;\n    right: 0px;\n    display: inline-block;\n    cursor: pointer;\n    width: 30px;\n    height: 25px;\n    margin-top: 2px;\n     \n}\n\n\n\n.sign p {\n    text-align: center;\n}\n\n\n\n.sign a {\n    display: inline;\n    color: black;\n    background: transparent;\n    font-weight: bolder;\n    \n}\n\n.sign img {\n    width: 300px;\n    height: 240px;\n}\n\n.sign input[type=\"checkbox\"] {\n    width: 50px;\n}\n.labelCheck{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.space{\n    display: inline-block;\n    width: 20px;\n    vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sign": `sign_sign__nGGNe`,
	"parentOfPwd": `sign_parentOfPwd__XY816`,
	"eye": `sign_eye__i9xeo`,
	"labelCheck": `sign_labelCheck__TpPQM`,
	"space": `sign_space__uFFV7`
};
export default ___CSS_LOADER_EXPORT___;
