// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portTable_portTable__1kWHA {
    table-layout: fixed;
    width: 100%;
    margin: 10px auto;
    border-collapse: collapse;
    border: 3px solid var(--color-bgTableaux);
    border-radius: 5px;
}

.portTable_portTable__1kWHA td:first-of-type {
  width:30%;
  padding-right: 5px;
  text-align: right;
}

.portTable_portTable__1kWHA td:nth-of-type(2) {
  width:20%;
  text-align: right;
}

.portTable_portTable__1kWHA td:nth-of-type(3){
  width: 20%;
  text-align: right;
}

.portTable_portTable__1kWHA tr:nth-last-of-type(even){
    background-color: var(--color-bgTableaux);
}

.portTable_portTable__1kWHA td {
  padding: 2px 3px;
}

@media screen and ( min-width: 390px ) {
  .portTable_portTable__1kWHA{
    max-width: 400px;
  }
  .portTable_portTable__1kWHA td:first-of-type {
  width:40%;
  padding-right: 20px;
}
}`, "",{"version":3,"sources":["webpack://./src/Components/PortTable/portTable.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,yBAAyB;IACzB,yCAAyC;IACzC,kBAAkB;AACtB;;AAEA;EACE,SAAS;EACT,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;EACA,SAAS;EACT,mBAAmB;AACrB;AACA","sourcesContent":[".portTable {\n    table-layout: fixed;\n    width: 100%;\n    margin: 10px auto;\n    border-collapse: collapse;\n    border: 3px solid var(--color-bgTableaux);\n    border-radius: 5px;\n}\n\n.portTable td:first-of-type {\n  width:30%;\n  padding-right: 5px;\n  text-align: right;\n}\n\n.portTable td:nth-of-type(2) {\n  width:20%;\n  text-align: right;\n}\n\n.portTable td:nth-of-type(3){\n  width: 20%;\n  text-align: right;\n}\n\n.portTable tr:nth-last-of-type(even){\n    background-color: var(--color-bgTableaux);\n}\n\n.portTable td {\n  padding: 2px 3px;\n}\n\n@media screen and ( min-width: 390px ) {\n  .portTable{\n    max-width: 400px;\n  }\n  .portTable td:first-of-type {\n  width:40%;\n  padding-right: 20px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portTable": `portTable_portTable__1kWHA`
};
export default ___CSS_LOADER_EXPORT___;
