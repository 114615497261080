// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../SignIn/sign.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignUp_signup__F2Yn- {
}

.SignUp_signup__F2Yn- h1{
    top:-40px;
}

.SignUp_signup__F2Yn- img{
     margin: auto;
     width: 100px;
     height: 80px;
}

.SignUp_signup__F2Yn- p {
    text-align: center;
}

.SignUp_signup__F2Yn- a {
    display: inline;
    color: gray;
    
}
.SignUp_signup__F2Yn- input[type="checkbox"] {
    width: 50px;
    margin-bottom: 0;
}
 
.SignUp_labelCheck__6MOV-{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.SignUp_space__eqsy5{
    display: inline-block;
    width: 0px;
    vertical-align: middle;
}

.SignUp_eye__lnsS4{
}

.SignUp_parentOfPwd__QOeI1 {
}

.SignUp_infos_cgi__yK0-n{
    font-size: 1rem;
    text-align: justify !important;
    margin: 10px 0;
}
.SignUp_signup__F2Yn- input[type="submit"] {
    height: 30px;
   color: black;
}
 `, "",{"version":3,"sources":["webpack://./src/Pages/SignUp/index.module.css"],"names":[],"mappings":"AAAA;AAEA;;AAEA;IACI,SAAS;AACb;;AAEA;KACK,YAAY;KACZ,YAAY;KACZ,YAAY;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,WAAW;;AAEf;AACA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,sBAAsB;AAC1B;;AAEA;AAEA;;AAEA;AAEA;;AAEA;IACI,eAAe;IACf,8BAA8B;IAC9B,cAAc;AAClB;AACA;IACI,YAAY;GACb,YAAY;AACf","sourcesContent":[".signup {\n    composes: sign from \"../SignIn/sign.module.css\";\n}\n\n.signup h1{\n    top:-40px;\n}\n\n.signup img{\n     margin: auto;\n     width: 100px;\n     height: 80px;\n}\n\n.signup p {\n    text-align: center;\n}\n\n.signup a {\n    display: inline;\n    color: gray;\n    \n}\n.signup input[type=\"checkbox\"] {\n    width: 50px;\n    margin-bottom: 0;\n}\n \n.labelCheck{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n}\n\n.space{\n    display: inline-block;\n    width: 0px;\n    vertical-align: middle;\n}\n\n.eye{\n    composes: eye from \"../SignIn/sign.module.css\";\n}\n\n.parentOfPwd {\n    composes: parentOfPwd from \"../SignIn/sign.module.css\";\n}\n\n.infos_cgi{\n    font-size: 1rem;\n    text-align: justify !important;\n    margin: 10px 0;\n}\n.signup input[type=\"submit\"] {\n    height: 30px;\n   color: black;\n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signup": `SignUp_signup__F2Yn- ${___CSS_LOADER_ICSS_IMPORT_0___.locals["sign"]}`,
	"labelCheck": `SignUp_labelCheck__6MOV-`,
	"space": `SignUp_space__eqsy5`,
	"eye": `SignUp_eye__lnsS4 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["eye"]}`,
	"parentOfPwd": `SignUp_parentOfPwd__QOeI1 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["parentOfPwd"]}`,
	"infos_cgi": `SignUp_infos_cgi__yK0-n`
};
export default ___CSS_LOADER_EXPORT___;
