// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Playfair+Display:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --main-font:'Open Sans', sans-serif;
    --title-font: 'Playfair Display', serif;
    --btn-font: 'Playfair Display', serif;
    --txt-color-light: rgb(255, 255, 255);
    --txt-color-dark: rgb(16, 15, 15) ;
    --txt-color-dark2: rgb(47, 46, 46) ;
    --color-1-dark: rgb(64,112,34); 
    --color-1-light: rgb(141,196,66);
    --color-2-dark: rgb(35,140,185);
    --color-2-light: rgba(74, 184, 231, 0.223);
    --color-bgTableaux:  rgb(242,241,239);
}`, "",{"version":3,"sources":["webpack://./src/variables.css"],"names":[],"mappings":"AAEA;IACI,mCAAmC;IACnC,uCAAuC;IACvC,qCAAqC;IACrC,qCAAqC;IACrC,kCAAkC;IAClC,mCAAmC;IACnC,8BAA8B;IAC9B,gCAAgC;IAChC,+BAA+B;IAC/B,0CAA0C;IAC1C,qCAAqC;AACzC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Playfair+Display:wght@400;700&display=swap');\n\n:root {\n    --main-font:'Open Sans', sans-serif;\n    --title-font: 'Playfair Display', serif;\n    --btn-font: 'Playfair Display', serif;\n    --txt-color-light: rgb(255, 255, 255);\n    --txt-color-dark: rgb(16, 15, 15) ;\n    --txt-color-dark2: rgb(47, 46, 46) ;\n    --color-1-dark: rgb(64,112,34); \n    --color-1-light: rgb(141,196,66);\n    --color-2-dark: rgb(35,140,185);\n    --color-2-light: rgba(74, 184, 231, 0.223);\n    --color-bgTableaux:  rgb(242,241,239);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
