// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../managePortfolio.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.existing_btns__fTD2Q {
} 

.existing_form_existing__8\\+QLi{
    display: flex;
    flex-direction: raw;
    width: 100%;
    max-width: 400px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.existing_form_existing__8\\+QLi label { 
    margin-top: 6px;
    display: inline-block;
    width: 45%;
    text-align: right;
}

.existing_select_wrap__RUBsz{
    display: block;
    width:45%;
    text-align: left;
}

.existing_infos__U8Cvc{
    width: 100%;
}

.existing_form_existing__8\\+QLi select,
.existing_form_existing__8\\+QLi input[type="number"],
.existing_form_existing__8\\+QLi input[type="text"] {
    display: inline-block;
    width: 150px;
    max-width: 150px;
    border-radius: 10px;
    padding: 4px;
    text-align: right;
    border: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/Pages/ManagePortfolio/Components/existing.module.css"],"names":[],"mappings":"AAAA;AAEA;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,qBAAqB;IACrB,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;;;IAGI,qBAAqB;IACrB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,uBAAuB;AAC3B","sourcesContent":[".btns {\n    composes: btns from \"../managePortfolio.module.css\";\n} \n\n.form_existing{\n    display: flex;\n    flex-direction: raw;\n    width: 100%;\n    max-width: 400px;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-items: center;\n}\n.form_existing label { \n    margin-top: 6px;\n    display: inline-block;\n    width: 45%;\n    text-align: right;\n}\n\n.select_wrap{\n    display: block;\n    width:45%;\n    text-align: left;\n}\n\n.infos{\n    width: 100%;\n}\n\n.form_existing select,\n.form_existing input[type=\"number\"],\n.form_existing input[type=\"text\"] {\n    display: inline-block;\n    width: 150px;\n    max-width: 150px;\n    border-radius: 10px;\n    padding: 4px;\n    text-align: right;\n    border: 1px solid black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btns": `existing_btns__fTD2Q ${___CSS_LOADER_ICSS_IMPORT_0___.locals["btns"]}`,
	"form_existing": `existing_form_existing__8+QLi`,
	"select_wrap": `existing_select_wrap__RUBsz`,
	"infos": `existing_infos__U8Cvc`
};
export default ___CSS_LOADER_EXPORT___;
