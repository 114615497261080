// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credit_credits__3BCuq {
  text-align: center;
  position: realative;
}

.credit_wrapper__O660d {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.credit_retour__QWvx7 {
  margin-top: 100px;
}
  
.credit_links__UKeRG {
 
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 1.25rem;
  padding: 10px 20px;
  margin: 1rem;
}

/******************BOX-SHADOW**************************/


.credit_links__UKeRG {
  background: linear-gradient(to right, #64f38c 0%, 50%, #f79d00 100%);
}

.credit_links__UKeRG:hover {
  animation: credit_neon-box-3__o1mr2 1s infinite alternate linear;
}

@keyframes credit_neon-box-3__o1mr2 {
  from {
    box-shadow: 2px 0 6px rgba(247, 157, 0, 0.7),
      -2px 0 6px rgba(100, 243, 140, 0.7), 5px 0 10px rgba(247, 157, 0, 0.7),
      -5px 0 10px rgba(100, 243, 140, 0.7);
  }

  to {
    box-shadow: 10px 0 20px rgba(247, 157, 0, 0.7),
      -10px 0 20px rgba(100, 243, 140, 0.7), 20px 0 40px rgba(247, 157, 0, 0.7),
      -20px 0 40px rgba(100, 243, 140, 0.7);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Credits/credit.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,UAAU;EACV,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;AACd;;AAEA,uDAAuD;;;AAGvD;EACE,oEAAoE;AACtE;;AAEA;EACE,gEAAkD;AACpD;;AAEA;EACE;IACE;;0CAEsC;EACxC;;EAEA;IACE;;2CAEuC;EACzC;AACF","sourcesContent":[".credits {\n  text-align: center;\n  position: realative;\n}\n\n.wrapper {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.retour {\n  margin-top: 100px;\n}\n  \n.links {\n \n  all: unset;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 20px;\n  text-transform: uppercase;\n  font-size: 1.25rem;\n  padding: 10px 20px;\n  margin: 1rem;\n}\n\n/******************BOX-SHADOW**************************/\n\n\n.links {\n  background: linear-gradient(to right, #64f38c 0%, 50%, #f79d00 100%);\n}\n\n.links:hover {\n  animation: neon-box-3 1s infinite alternate linear;\n}\n\n@keyframes neon-box-3 {\n  from {\n    box-shadow: 2px 0 6px rgba(247, 157, 0, 0.7),\n      -2px 0 6px rgba(100, 243, 140, 0.7), 5px 0 10px rgba(247, 157, 0, 0.7),\n      -5px 0 10px rgba(100, 243, 140, 0.7);\n  }\n\n  to {\n    box-shadow: 10px 0 20px rgba(247, 157, 0, 0.7),\n      -10px 0 20px rgba(100, 243, 140, 0.7), 20px 0 40px rgba(247, 157, 0, 0.7),\n      -20px 0 40px rgba(100, 243, 140, 0.7);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"credits": `credit_credits__3BCuq`,
	"wrapper": `credit_wrapper__O660d`,
	"retour": `credit_retour__QWvx7`,
	"links": `credit_links__UKeRG`,
	"neon-box-3": `credit_neon-box-3__o1mr2`
};
export default ___CSS_LOADER_EXPORT___;
