// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Attention pour les dimensions du meter indispensable de passer
par des variables ( et de les setter 2* -> pour la div container et 
Pour le svg) */

.portfolio_portfolio__qkb9d{
   
    --metW :80vw;
    --metH : calc((var(--metW))*0.58);
}

@media  screen and (min-width:480px) {
   .portfolio_portfolio__qkb9d{
    --metW :400px;
    --metH :230px; 
    }
}

/* .portfolio h1{
    position: relative;
    top :-90px;
    left: calc(50vw);
    transform: translateX(-50%);
    white-space: nowrap;
    margin-bottom: -40px;
} */

.portfolio_portfolio__qkb9d .portfolio_meter_container__J0GK9 {
     text-align: center;
    width: var(--metW);
    height: var(--metH); 
    margin: auto;
    /* text-align: center; */  
}

.portfolio_under_table__Fj7hf{
    margin-top: 10px;
    text-align: center;
}

.portfolio_under_table__Fj7hf a {
    width: 250px;
}
 `, "",{"version":3,"sources":["webpack://./src/Pages/Portfolio/portfolio.module.css"],"names":[],"mappings":";AACA;;cAEc;;AAEd;;IAEI,YAAY;IACZ,iCAAiC;AACrC;;AAEA;GACG;IACC,aAAa;IACb,aAAa;IACb;AACJ;;AAEA;;;;;;;GAOG;;AAOH;KACK,kBAAkB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["\n/* Attention pour les dimensions du meter indispensable de passer\npar des variables ( et de les setter 2* -> pour la div container et \nPour le svg) */\n\n.portfolio{\n   \n    --metW :80vw;\n    --metH : calc((var(--metW))*0.58);\n}\n\n@media  screen and (min-width:480px) {\n   .portfolio{\n    --metW :400px;\n    --metH :230px; \n    }\n}\n\n/* .portfolio h1{\n    position: relative;\n    top :-90px;\n    left: calc(50vw);\n    transform: translateX(-50%);\n    white-space: nowrap;\n    margin-bottom: -40px;\n} */\n\n:export {\n    meterWidth: var(--metW);\n    meterHeight: var(--metH);\n}\n\n.portfolio .meter_container {\n     text-align: center;\n    width: var(--metW);\n    height: var(--metH); \n    margin: auto;\n    /* text-align: center; */  \n}\n\n.under_table{\n    margin-top: 10px;\n    text-align: center;\n}\n\n.under_table a {\n    width: 250px;\n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meterWidth": `var(--metW)`,
	"meterHeight": `var(--metH)`,
	"portfolio": `portfolio_portfolio__qkb9d`,
	"meter_container": `portfolio_meter_container__J0GK9`,
	"under_table": `portfolio_under_table__Fj7hf`
};
export default ___CSS_LOADER_EXPORT___;
