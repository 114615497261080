// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.notFound_page404__FTDB0{
    text-align: center;
}
.notFound_page404__FTDB0 H1 {
      top :0px;
      margin: 50px 0 10px 0;   
}
`, "",{"version":3,"sources":["webpack://./src/Pages/NotFound/notFound.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;AACtB;AACA;MACM,QAAQ;MACR,qBAAqB;AAC3B","sourcesContent":["\n.page404{\n    text-align: center;\n}\n.page404 H1 {\n      top :0px;\n      margin: 50px 0 10px 0;   \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page404": `notFound_page404__FTDB0`
};
export default ___CSS_LOADER_EXPORT___;
