// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../NewTrade/newTrade.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adjust_adjust__s7Fn6{
  position: relative;
}


.adjust_form_reEnter__4IaR\\+ {
}

.adjust_input_wrap__FTovr,
.adjust_input_wrapLong__3NSWs {
  display: block;
  width: 45%;
  text-align: left;
}

.adjust_form_reEnter__4IaR\\+ .adjust_label__BDvbU {
  width: 28% !important;
}

.adjust_form_reEnter__4IaR\\+ .adjust_input_wrap__FTovr {
  width: 68% !important;
}

.adjust_local_wrapper__j0czU{
  margin: auto;
}
/* Attention pour les dimensions du meter indispensable de passer
par des variables ( et de les setter 2* -> pour la div container et 
Pour le svg) */
.adjust_adjust_page__xedon {
  --metW: 70vw;
  --metH: calc((var(--metW)) * 0.60);
  text-align: center;
  width: var(--metW);
  height: var(--metH);
  margin: auto;
}

@media screen and (min-width: 480px) {
  .adjust_adjust_page__xedon {
    --metW: 320px !important;
    --metH: 185px;
    text-align: center;
  width: var(--metW);
  height: var(--metH);
  margin: auto;
  
  }
}

.adjust_next_prev__voeqD {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adjust_next__OYnd9,
.adjust_prev__83IzV {
  margin-top: 10px;
  padding: 5px;
  background-color: rgba(211, 211, 211, 0.26);
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Adjust/adjust.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;;AAGA;AAEA;;AAEA;;EAEE,cAAc;EACd,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;AACA;;cAEc;AACd;EACE,YAAY;EACZ,kCAAkC;EAClC,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE;IACE,wBAAwB;IACxB,aAAa;IACb,kBAAkB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;;EAEZ;AACF;;AAOA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;;EAEE,gBAAgB;EAChB,YAAY;EACZ,2CAA2C;EAC3C,kBAAkB;AACpB","sourcesContent":[".adjust{\n  position: relative;\n}\n\n\n.form_reEnter {\n  composes: form_enter from \"../NewTrade/newTrade.module.css\";\n}\n\n.input_wrap,\n.input_wrapLong {\n  display: block;\n  width: 45%;\n  text-align: left;\n}\n\n.form_reEnter .label {\n  width: 28% !important;\n}\n\n.form_reEnter .input_wrap {\n  width: 68% !important;\n}\n\n.local_wrapper{\n  margin: auto;\n}\n/* Attention pour les dimensions du meter indispensable de passer\npar des variables ( et de les setter 2* -> pour la div container et \nPour le svg) */\n.adjust_page {\n  --metW: 70vw;\n  --metH: calc((var(--metW)) * 0.60);\n  text-align: center;\n  width: var(--metW);\n  height: var(--metH);\n  margin: auto;\n}\n\n@media screen and (min-width: 480px) {\n  .adjust_page {\n    --metW: 320px !important;\n    --metH: 185px;\n    text-align: center;\n  width: var(--metW);\n  height: var(--metH);\n  margin: auto;\n  \n  }\n}\n\n:export {\n  meterWidth: var(--metW);\n  meterHeight: var(--metH);\n}\n\n.next_prev {\n  position: absolute;\n  top: 0;\n  right: 0;\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.next,\n.prev {\n  margin-top: 10px;\n  padding: 5px;\n  background-color: rgba(211, 211, 211, 0.26);\n  border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meterWidth": `var(--metW)`,
	"meterHeight": `var(--metH)`,
	"adjust": `adjust_adjust__s7Fn6`,
	"form_reEnter": `adjust_form_reEnter__4IaR+ ${___CSS_LOADER_ICSS_IMPORT_0___.locals["form_enter"]}`,
	"input_wrap": `adjust_input_wrap__FTovr`,
	"input_wrapLong": `adjust_input_wrapLong__3NSWs`,
	"label": `adjust_label__BDvbU`,
	"local_wrapper": `adjust_local_wrapper__j0czU`,
	"adjust_page": `adjust_adjust_page__xedon`,
	"next_prev": `adjust_next_prev__voeqD`,
	"next": `adjust_next__OYnd9`,
	"prev": `adjust_prev__83IzV`
};
export default ___CSS_LOADER_EXPORT___;
