// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legal_legal__a8Fxs{
    padding: 15px;
    max-width: 800px;
    margin: auto;
}

.legal_center__spcpN{
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Legal/legal.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".legal{\n    padding: 15px;\n    max-width: 800px;\n    margin: auto;\n}\n\n.center{\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legal": `legal_legal__a8Fxs`,
	"center": `legal_center__spcpN`
};
export default ___CSS_LOADER_EXPORT___;
