// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Attention pour les dimensions du meter indispensable de passer
par des variables ( et de les setter 2* -> pour la div container et 
Pour le svg) */

.Management_metrics__\\+nnAd 
{
    width: 100%;
    margin: auto;
    border-radius: 30px;
    margin-bottom: 20px;
    box-shadow: 1px 2px 10px 1px rgb(201, 201, 201);
    padding: 10px;
    text-align: start;
}

.Management_portfolio__fU8Tp{
    --metW :80vw;
    --metH : calc((var(--metW))*0.58);
    width: 100%;
    margin: auto;
    border-radius: 30px;
    margin-bottom: 20px;
    box-shadow: 1px 2px 10px 1px rgb(201, 201, 201);
   
    text-align: start;
}
@media  screen and (min-width:390px) {
   .Management_portfolio__fU8Tp{
    padding: 10px;
    }
}

@media  screen and (min-width:480px) {
   .Management_portfolio__fU8Tp{
    --metW :400px;
    --metH :230px; 
    }
}

.Management_portfolio__fU8Tp .Management_meter_container__LVCPB {
     text-align: center;
    width: var(--metW);
    height: var(--metH); 
    margin: auto;
    /* text-align: center; */  
}

.Management_risk__Uau6B{
    background-color: rgb(213, 202, 202);
    text-align: center;
     border-radius: 10px;
 
  border: 1px solid rgba(0, 0, 0, 0.094);
}`, "",{"version":3,"sources":["webpack://./src/Pages/NewTrade/Components/Management.module.css"],"names":[],"mappings":"AAAA;;cAEc;;AAEd;;IAEI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,+CAA+C;IAC/C,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,iCAAiC;IACjC,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,+CAA+C;;IAE/C,iBAAiB;AACrB;AACA;GACG;IACC,aAAa;IACb;AACJ;;AAEA;GACG;IACC,aAAa;IACb,aAAa;IACb;AACJ;;AAOA;KACK,kBAAkB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;KACjB,mBAAmB;;EAEtB,sCAAsC;AACxC","sourcesContent":["/* Attention pour les dimensions du meter indispensable de passer\npar des variables ( et de les setter 2* -> pour la div container et \nPour le svg) */\n\n.metrics \n{\n    width: 100%;\n    margin: auto;\n    border-radius: 30px;\n    margin-bottom: 20px;\n    box-shadow: 1px 2px 10px 1px rgb(201, 201, 201);\n    padding: 10px;\n    text-align: start;\n}\n\n.portfolio{\n    --metW :80vw;\n    --metH : calc((var(--metW))*0.58);\n    width: 100%;\n    margin: auto;\n    border-radius: 30px;\n    margin-bottom: 20px;\n    box-shadow: 1px 2px 10px 1px rgb(201, 201, 201);\n   \n    text-align: start;\n}\n@media  screen and (min-width:390px) {\n   .portfolio{\n    padding: 10px;\n    }\n}\n\n@media  screen and (min-width:480px) {\n   .portfolio{\n    --metW :400px;\n    --metH :230px; \n    }\n}\n\n:export {\n    meterWidth: var(--metW);\n    meterHeight: var(--metH);\n}\n\n.portfolio .meter_container {\n     text-align: center;\n    width: var(--metW);\n    height: var(--metH); \n    margin: auto;\n    /* text-align: center; */  \n}\n\n.risk{\n    background-color: rgb(213, 202, 202);\n    text-align: center;\n     border-radius: 10px;\n \n  border: 1px solid rgba(0, 0, 0, 0.094);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meterWidth": `var(--metW)`,
	"meterHeight": `var(--metH)`,
	"metrics": `Management_metrics__+nnAd`,
	"portfolio": `Management_portfolio__fU8Tp`,
	"meter_container": `Management_meter_container__LVCPB`,
	"risk": `Management_risk__Uau6B`
};
export default ___CSS_LOADER_EXPORT___;
