// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressBarr_wrapper__pmWCm{
    position: relative;
    margin: 10px auto;
    width: 100%;
    max-width: 350px;
    height: 40px;
    font-size: 1.1rem;
    text-align: center;
     
}

.progressBarr_bar__gk3fl{
    background-color:black;
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    height: 1px; 
    width: 90%;   
  
}

.progressBarr_cursor__MKwqB{
    position: absolute;
    background-color: black;
    top: -10px;
    height: 20px;
    width: 3px;  
    transform: translateX(-1px);
}

.progressBarr_neutral__MqIf\\+{
    position: absolute;
    background-color: rgba(38, 0, 255, 0.62);
    border-radius: 6px;
    top: -4px;
    height: 9px;
    width: 9px;  
     transform: translateX(-4px);
}

.progressBarr_target__tHvjs{
    position: absolute;
    bottom: -18px;
    right: 0px;
}

.progressBarr_min__TVTBm{
    position: absolute;
    bottom: -18px;
    left: 0px;
}

.progressBarr_now__LEreG{
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translateX(-50%);
}


  `, "",{"version":3,"sources":["webpack://./src/Components/ProgressBar/progressBarr.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;;AAEtB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,gCAAgC;IAChC,WAAW;IACX,UAAU;;AAEd;;AAEA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,UAAU;IACV,YAAY;IACZ,UAAU;IACV,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,wCAAwC;IACxC,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,UAAU;KACT,2BAA2B;AAChC;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,2BAA2B;AAC/B","sourcesContent":[".wrapper{\n    position: relative;\n    margin: 10px auto;\n    width: 100%;\n    max-width: 350px;\n    height: 40px;\n    font-size: 1.1rem;\n    text-align: center;\n     \n}\n\n.bar{\n    background-color:black;\n    position: absolute;\n    top: 40%;\n    left: 50%;\n    z-index: 10;\n    transform: translate(-50%, -50%);\n    height: 1px; \n    width: 90%;   \n  \n}\n\n.cursor{\n    position: absolute;\n    background-color: black;\n    top: -10px;\n    height: 20px;\n    width: 3px;  \n    transform: translateX(-1px);\n}\n\n.neutral{\n    position: absolute;\n    background-color: rgba(38, 0, 255, 0.62);\n    border-radius: 6px;\n    top: -4px;\n    height: 9px;\n    width: 9px;  \n     transform: translateX(-4px);\n}\n\n.target{\n    position: absolute;\n    bottom: -18px;\n    right: 0px;\n}\n\n.min{\n    position: absolute;\n    bottom: -18px;\n    left: 0px;\n}\n\n.now{\n    position: absolute;\n    bottom: -18px;\n    left: 50%;\n    transform: translateX(-50%);\n}\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `progressBarr_wrapper__pmWCm`,
	"bar": `progressBarr_bar__gk3fl`,
	"cursor": `progressBarr_cursor__MKwqB`,
	"neutral": `progressBarr_neutral__MqIf+`,
	"target": `progressBarr_target__tHvjs`,
	"min": `progressBarr_min__TVTBm`,
	"now": `progressBarr_now__LEreG`
};
export default ___CSS_LOADER_EXPORT___;
