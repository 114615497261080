// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navPortfolio_navPortfoliosContainer__ZE2Nc {
  background-color: var(--color-bgTableaux);
  width: 100%;
  border-top: rgba(0, 0, 0, 0.234) 1px solid;
  border-bottom: rgba(0, 0, 0, 0.294) 1px solid; 
  overflow-y: auto;
}

.navPortfolio_navPortfolios__Gx9Tg{
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  display: flex;
  min-height: 30px;
}

.navPortfolio_button__Dtm5A {
  font-size: 18px;
  padding: 5px 10px;
  text-decoration: none;
  height: 100%;
  white-space: nowrap;
  color: #545e6f;
  background: #f0f0f0;
} 
.navPortfolio_active__NIj6j{
  color: #fff;
  background: #696969;
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/navPortfolio.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,WAAW;EACX,0CAA0C;EAC1C,6CAA6C;EAC7C,gBAAgB;AAClB;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,qBAAqB;EACrB,YAAY;EACZ,mBAAmB;EACnB,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".navPortfoliosContainer {\n  background-color: var(--color-bgTableaux);\n  width: 100%;\n  border-top: rgba(0, 0, 0, 0.234) 1px solid;\n  border-bottom: rgba(0, 0, 0, 0.294) 1px solid; \n  overflow-y: auto;\n}\n\n.navPortfolios{\n  width: fit-content;\n  margin: auto;\n  display: flex;\n  min-height: 30px;\n}\n\n.button {\n  font-size: 18px;\n  padding: 5px 10px;\n  text-decoration: none;\n  height: 100%;\n  white-space: nowrap;\n  color: #545e6f;\n  background: #f0f0f0;\n} \n.active{\n  color: #fff;\n  background: #696969;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navPortfoliosContainer": `navPortfolio_navPortfoliosContainer__ZE2Nc`,
	"navPortfolios": `navPortfolio_navPortfolios__Gx9Tg`,
	"button": `navPortfolio_button__Dtm5A`,
	"active": `navPortfolio_active__NIj6j`
};
export default ___CSS_LOADER_EXPORT___;
