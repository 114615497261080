// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_modal__KtSWS {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: rgba(49,49,49,0.8);
    z-index: 10;
}

.modal_content__O8ZBY {
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    font-size: 1.8rem;
    background: #f1f1f1;
    padding: 28px 28px;
    border-radius: 10px;
    max-width: 600px;
    min-width: 300px;
}

.modal_btn_close__aUkHk {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 1px 2px;
    border: none;
}

.modal_btn_close__aUkHk:hover{
     cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Modal/modal.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,eAAe;IACf,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,gCAAgC;IAChC,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,YAAY;AAChB;;AAEA;KACK,eAAe;AACpB","sourcesContent":[".modal {\n    width: 100vw;\n    height: 100vh;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    position: fixed;\n    background: rgba(49,49,49,0.8);\n    z-index: 10;\n}\n\n.content {\n    position: absolute;\n    top: 40%;\n    left: 50%;\n    z-index: 10;\n    transform: translate(-50%, -50%);\n    line-height: 1.4;\n    font-size: 1.8rem;\n    background: #f1f1f1;\n    padding: 28px 28px;\n    border-radius: 10px;\n    max-width: 600px;\n    min-width: 300px;\n}\n\n.btn_close {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    padding: 1px 2px;\n    border: none;\n}\n\n.btn_close:hover{\n     cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `modal_modal__KtSWS`,
	"content": `modal_content__O8ZBY`,
	"btn_close": `modal_btn_close__aUkHk`
};
export default ___CSS_LOADER_EXPORT___;
