// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Attention pour les dimensions du meter indispensable de passer
par des variables ( et de les setter 2* -> pour la div container et 
Pour le svg) */

.perfMeter_opacify__iF\\+-c {
  opacity: 0.2;
}

.perfMeter_wrapper_meter__HAxFB {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.perfMeter_alertInvalid__45z4I {
  position: absolute;
  display:none; /* Hidden by default */
  z-index: 2;  
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.perfMeter_alertVisible__JBlS8{
  display: block;
}
.perfMeter_alertInvalid_content__HDawc{
 background-color: #fefefe;
  margin: 50px auto; /* 15% from the top and centered */
  padding: 20px;
  border: 3px solid red;
  border-radius: 30px;
  width: -moz-fit-content;
  width: fit-content;
}

.perfMeter_adjust__Oe0Lc .perfMeter_meter_container__wdmqz {
  text-align: center;
  width: var(--metW);
  height: var(--metH);
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/Components/PerfMeter/perfMeter.module.css"],"names":[],"mappings":"AAAA;;cAEc;;AAEd;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,YAAY,EAAE,sBAAsB;EACpC,UAAU;EACV,OAAO;EACP,MAAM;EACN,WAAW,EAAE,eAAe;EAC5B,YAAY,EAAE,gBAAgB;EAC9B,8BAA8B,EAAE,mBAAmB;EACnD,oCAAoC,EAAE,qBAAqB;AAC7D;AACA;EACE,cAAc;AAChB;AACA;CACC,yBAAyB;EACxB,iBAAiB,EAAE,kCAAkC;EACrD,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;AACd","sourcesContent":["/* Attention pour les dimensions du meter indispensable de passer\npar des variables ( et de les setter 2* -> pour la div container et \nPour le svg) */\n\n.opacify {\n  opacity: 0.2;\n}\n\n.wrapper_meter {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  margin-bottom: 15px;\n}\n.alertInvalid {\n  position: absolute;\n  display:none; /* Hidden by default */\n  z-index: 2;  \n  left: 0;\n  top: 0;\n  width: 100%; /* Full width */\n  height: 100%; /* Full height */\n  background-color: rgb(0, 0, 0); /* Fallback color */\n  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */\n}\n.alertVisible{\n  display: block;\n}\n.alertInvalid_content{\n background-color: #fefefe;\n  margin: 50px auto; /* 15% from the top and centered */\n  padding: 20px;\n  border: 3px solid red;\n  border-radius: 30px;\n  width: fit-content;\n}\n\n.adjust .meter_container {\n  text-align: center;\n  width: var(--metW);\n  height: var(--metH);\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"opacify": `perfMeter_opacify__iF+-c`,
	"wrapper_meter": `perfMeter_wrapper_meter__HAxFB`,
	"alertInvalid": `perfMeter_alertInvalid__45z4I`,
	"alertVisible": `perfMeter_alertVisible__JBlS8`,
	"alertInvalid_content": `perfMeter_alertInvalid_content__HDawc`,
	"adjust": `perfMeter_adjust__Oe0Lc`,
	"meter_container": `perfMeter_meter_container__wdmqz`
};
export default ___CSS_LOADER_EXPORT___;
