// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!./existing.module.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../managePortfolio.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create_form_create__KLNMI {
}

.create_input_wrap__H3nRM {
}
.create_input_wrap_initAmout__73lyX{
    width: 45%;
    display: flex;
}

.create_input_wrap_initAmout__73lyX input[type="number"] {
    width: calc(90% - 75px ) !important;
}
.create_input_wrap_initAmout__73lyX select {
    width: 60px !important; 
    margin-left: 2px;
}

.create_btns__FCYFU {
} 
`, "",{"version":3,"sources":["webpack://./src/Pages/ManagePortfolio/Components/create.module.css"],"names":[],"mappings":"AAAA;AAEA;;AAEA;AAEA;AACA;IACI,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,mCAAmC;AACvC;AACA;IACI,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;AAEA","sourcesContent":[".form_create {\n    composes: form_existing from \"./existing.module.css\"\n}\n\n.input_wrap {\n    composes: from select_wrap from \"./existing.module.css\"\n}\n.input_wrap_initAmout{\n    width: 45%;\n    display: flex;\n}\n\n.input_wrap_initAmout input[type=\"number\"] {\n    width: calc(90% - 75px ) !important;\n}\n.input_wrap_initAmout select {\n    width: 60px !important; \n    margin-left: 2px;\n}\n\n.btns {\n    composes: btns from \"../managePortfolio.module.css\";\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_create": `create_form_create__KLNMI ${___CSS_LOADER_ICSS_IMPORT_0___.locals["form_existing"]}`,
	"input_wrap": `create_input_wrap__H3nRM ${___CSS_LOADER_ICSS_IMPORT_0___.locals["from"]} ${___CSS_LOADER_ICSS_IMPORT_0___.locals["select_wrap"]}`,
	"input_wrap_initAmout": `create_input_wrap_initAmout__73lyX`,
	"btns": `create_btns__FCYFU ${___CSS_LOADER_ICSS_IMPORT_1___.locals["btns"]}`
};
export default ___CSS_LOADER_EXPORT___;
