// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_myfooter__LCoz2{
    text-align: center;
    margin-top: 20px;
    background: var(--color-bgTableaux);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
    border-top: rgba(0, 0, 0, 0.234) 1px solid;
     
   
}


.footer_myfooter__LCoz2 a {
    font-size: 1.2rem ;
    margin: 0 15px;
    width: 160px;
    background-color: #fff;
     
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/footer.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mCAAmC;IACnC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;;IAEnB,0CAA0C;;;AAG9C;;;AAGA;IACI,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,sBAAsB;;AAE1B","sourcesContent":[".myfooter{\n    text-align: center;\n    margin-top: 20px;\n    background: var(--color-bgTableaux);\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n  \n    border-top: rgba(0, 0, 0, 0.234) 1px solid;\n     \n   \n}\n\n\n.myfooter a {\n    font-size: 1.2rem ;\n    margin: 0 15px;\n    width: 160px;\n    background-color: #fff;\n     \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myfooter": `footer_myfooter__LCoz2`
};
export default ___CSS_LOADER_EXPORT___;
