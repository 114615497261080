// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input:hover{
     cursor: pointer;
}
 
.btn_myBtn__717dR {
    display: inline-block;
    margin: auto;
    width: auto;
    background-color: var(--color-1-light);
    font-family: var(--btn-font);
    color: var(--txt-color-dark2);
    text-transform: uppercase;
    /* font-weight: bold;   */
    letter-spacing: 0.5px;
    overflow: hidden;
    border-radius: 30px;
    border: solid rgba(0, 0, 0, 0.446) 1px;
    padding: 4px 10px 4px 10px ;
}
.btn_myBtn__717dR:hover :not(disabled){
    cursor: pointer;
    border: solid black 2px;
    color: var(--txt-color-dark);
    padding: 3px 9px 3px 9px ;
}

input[type="submit"]
{
    background-color: var(--color-1-light);
}

.btn_cancel__p8yuU{
    background-color: red;
    color: blanchedalmond;
}


*:disabled {
  background-color: lightgray !important;
  color: linen;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/btn.module.css"],"names":[],"mappings":"AAAA;KACK,eAAe;AACpB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,WAAW;IACX,sCAAsC;IACtC,4BAA4B;IAC5B,6BAA6B;IAC7B,yBAAyB;IACzB,yBAAyB;IACzB,qBAAqB;IACrB,gBAAgB;IAChB,mBAAmB;IACnB,sCAAsC;IACtC,2BAA2B;AAC/B;AACA;IACI,eAAe;IACf,uBAAuB;IACvB,4BAA4B;IAC5B,yBAAyB;AAC7B;;AAEA;;IAEI,sCAAsC;AAC1C;;AAEA;IACI,qBAAqB;IACrB,qBAAqB;AACzB;;;AAGA;EACE,sCAAsC;EACtC,YAAY;EACZ,UAAU;AACZ","sourcesContent":["input:hover{\n     cursor: pointer;\n}\n \n.myBtn {\n    display: inline-block;\n    margin: auto;\n    width: auto;\n    background-color: var(--color-1-light);\n    font-family: var(--btn-font);\n    color: var(--txt-color-dark2);\n    text-transform: uppercase;\n    /* font-weight: bold;   */\n    letter-spacing: 0.5px;\n    overflow: hidden;\n    border-radius: 30px;\n    border: solid rgba(0, 0, 0, 0.446) 1px;\n    padding: 4px 10px 4px 10px ;\n}\n.myBtn:hover :not(disabled){\n    cursor: pointer;\n    border: solid black 2px;\n    color: var(--txt-color-dark);\n    padding: 3px 9px 3px 9px ;\n}\n\ninput[type=\"submit\"]\n{\n    background-color: var(--color-1-light);\n}\n\n.cancel{\n    background-color: red;\n    color: blanchedalmond;\n}\n\n\n*:disabled {\n  background-color: lightgray !important;\n  color: linen;\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myBtn": `btn_myBtn__717dR`,
	"cancel": `btn_cancel__p8yuU`
};
export default ___CSS_LOADER_EXPORT___;
